<style>
  .container-items{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    justify-content: center;
  }
  .leaflet-popup-content{
    width: 300px!important;
  }

  .filter-container{
    width: 245px;
  }

  .maps-container {
    width: 100%;
    height: 75vh;
  }

  .vue2leaflet-map .leaflet-shadow-pane {
    display: none !important;
  }

  .leaflet-popup-content{
    width: 200px;
  }
</style>
<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <div class="maps-container" v-if="status_mapa">
            <l-map style="height: 100%; overflow: hidden;" :zoom="zoom" :center="center" ref="mymap" :options="{attributionControl: false, fullscreenControl: true}">
              <l-control-attribution position="bottomright" prefix="Spore Cloud"></l-control-attribution>
              <l-control-layers
                :position="layersPosition"
                :collapsed="true"
                :sort-layers="true"
              />
              <l-control :position="layersPosition">
                <b-button v-if="!showFiltrar" variant="primarySpore" style="background:#fff;" class="btn-icon rounded-circle" @click="showFiltrar = !showFiltrar" v-b-tooltip.hover.left :title="!showFiltrar ? 'Mostrar Filtros' : 'Ocultar Filtros'">
                  <feather-icon icon="FilterIcon" />
                </b-button>

                <div v-if="showFiltrar" class="filter-container bg-white border-2 border-solid border-grey py-4 px-2 rounded-lg mt-1">
                  <div class="font-bold mb-2 text-center">
                    <b>Filtrar Por:</b>
                  </div>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Serie"
                      >
                        <b-form-input
                          v-model= "serie"
                          placeholder="Escribir la serie"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label="Estatus"
                      >
                        <v-select
                        placeholder= "seleccionar status"
                        v-model="status"
                        :options="opcStatus"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12" class="text-center">
                      <b-button
                        variant="primarySpore"
                        @click="getMapa"
                      >
                        <feather-icon
                          icon="SearchIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Buscar</span>
                      </b-button>
                    </b-col>
                    <b-col md="12" class="text-center mt-1">
                      <b-button
                        variant="danger"
                        @click="showFiltrar = !showFiltrar"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Cancelar</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </l-control>
              <l-control :position="layersPosition">
                <b-button variant="info" style="background:#fff;" class="btn-icon rounded-circle" :href="urlDownload" download="map.kml" v-b-tooltip.hover.left title="Descargar .kml">
                  <feather-icon icon="ExternalLinkIcon" />
                </b-button>
              </l-control>
              <l-control :position="layersPosition">
                <b-button variant="info" style="background:#fff;" class="btn-icon rounded-circle" :href="urlDownloadCsv" download="medidores_detalle.csv" v-b-tooltip.hover.left title="Descargar .csv">
                  <feather-icon icon="DownloadIcon" />
                </b-button>
              </l-control>
              <l-tile-layer
                v-for="tileProvider in tileProviders"
                :key="tileProvider.name"
                :name="tileProvider.name"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                layer-type="base"
              />
              <v-marker-cluster>
                <l-marker
                  v-for="marker in markers"
                  :key="marker.id"
                  :visible="marker.visible"
                  :draggable="marker.draggable"
                  :lat-lng.sync="marker.position"
                >
                  <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor" >
                    <b-img
                      style="width: 30px !important"
                      v-if="!isEmpty(marker.date) && marker.transmitting === 1"
                      rounded
                      :src="marker.status === '1' ? require(`@/assets/images/markers/success.svg`) : require(`@/assets/images/markers/warning.svg`)"
                    />
                    <b-img
                      style="width: 30px !important"
                      v-if="!isEmpty(marker.date) && marker.transmitting === 0"
                      rounded
                      :src="require(`@/assets/images/markers/danger.svg`)"
                    />
                    <b-img
                      style="width: 30px !important"
                      v-if="isEmpty(marker.date)"
                      rounded
                      :src="require(`@/assets/images/markers/11.svg`)"
                    />
                  </l-icon>

                  <l-popup>
                    <gridPrincipalItem
                      img="energy"
                      name=""
                      description=""
                    ></gridPrincipalItem>
                    <valueItem
                      :value="marker.serie"
                      description="Serie"
                      img="alias"
                    ></valueItem>
                    <valueItem
                      :value="formatDate(marker.date)"
                      description="Fecha de transmisión"
                      img="fecha"
                    ></valueItem>
                    <div class="text-nowrap mb-1">
                      <b-avatar
                        size="32"
                        class="avatar-border-2 box-shadow-1"
                        :variant="marker.status === '1' ? 'success' : 'secondary'"
                      >
                        <feather-icon class="align-text-top" v-if="marker.status === '1'" icon="ZapIcon" size="18" />
                        <feather-icon class="align-text-top" v-else icon="ZapOffIcon" size="18"/>
                      </b-avatar>
                      <b>
                        <label class="ml-2" style="font-size:18px;" :class="marker.status === '1' ? 'text-success' : 'text-secondary'">{{marker.status === '1' ? 'con servicio' : 'sin servicio' }}</label>
                      </b>
                    </div>
                    <div class="text-nowrap mb-1">
                      <b-avatar
                        size="32"
                        class="avatar-border-2 box-shadow-1"
                        :variant="(!isEmpty(marker.date) && marker.transmitting === 1 ? 'success' : (!isEmpty(marker.date) && marker.transmitting === 0 ? 'danger' : 'secondary'))"
                      >
                        <feather-icon class="align-text-top" v-if="!isEmpty(marker.date) && marker.transmitting === 1" icon="WifiIcon" size="18"/>
                        <feather-icon class="align-text-top" v-if="!isEmpty(marker.date) && marker.transmitting === 0" icon="WifiOffIcon" size="18" />
                        <feather-icon class="align-text-top" v-if="isEmpty(marker.date)" icon="WifiOffIcon" size="18"/>
                      </b-avatar>
                      <b>
                        <label class="ml-2" style="font-size:18px;" :class="(!isEmpty(marker.date) && marker.transmitting === 1 ? 'text-success' : (!isEmpty(marker.date) && marker.transmitting === 0 ? 'text-danger' : 'text-secondary'))">
                          {{ (!isEmpty(marker.date) && marker.transmitting === 1 ? 'Transmitiendo' : (!isEmpty(marker.date) && marker.transmitting === 0 ? 'Sin transmitir hace 24 hrs' : 'No ha transmitido')) }}
                        </label>
                      </b>
                    </div>
                    <div class="text-center">
                      <b-button
                      variant="primarySpore"
                      @click="deviceInformation(marker.uuid)">
                        Ver Más
                      </b-button>
                    </div>
                  </l-popup>
                </l-marker>
              </v-marker-cluster>
            </l-map>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BAvatar, BImg, BFormInput, VBTooltip
} from 'bootstrap-vue'
import request from '@/libs/request/index'
import { formatDateLanguages, empty } from '@/libs/tools/helpers'
import GeoJSON from 'geojson'
import tokml from 'geojson-to-kml'
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LControl,
  LControlLayers,
  LControlAttribution
} from 'vue2-leaflet'
import vSelect from 'vue-select'
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster/Vue2LeafletMarkercluster'
import gridPrincipalItem from '@/@core/spore-components/gridPrincipalItem/gridPrincipalItem.vue'
import valueItem from '@/@core/spore-components/valueItem/valueItem.vue'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
/* global L:true */

const tileProviders = [
  {
    name: 'Gris',
    visible: true,
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
  },
  {
    name: 'Calles',
    visible: false,
    url: 'https://{s}.tile.openstreetmap.fr/osmfr//{z}/{x}/{y}.png'
  },
  {
    name: 'Satelite',
    visible: false,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
  }
]

export default {
  name: 'mapa',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LPopup,
    LIcon,
    LControlLayers,
    LControlAttribution,
    'v-marker-cluster': Vue2LeafletMarkercluster,
    gridPrincipalItem,
    valueItem,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    vSelect,
    BAvatar,
    BImg,
    BFormInput
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      iconSize: 50,
      filter: {
        type: ''
      },
      tileProviders,
      layersPosition: 'topright',
      center: [20.662396323989693, -103.37640969334421],
      zoom: 10,
      staticAnchor: [12, 27],
      response: [],
      markers: [],
      marker2: [],
      status_mapa: true,
      showFiltrar: false,
      allSelected: true,
      positions: [],
      URL,
      status: '',
      numberGaninets: '',
      serie: '',
      urlDownload: '',
      urlDownloadCsv: '',
      responseDataCsv: [],
      opcStatus: [
        {
          label: 'Con servicio',
          value: '1',
          title: 'Con servicio'
        },
        {
          label: 'Sin servicio',
          value: '0',
          title: 'Sin servicio'
        }
      ]
    }
  },
  computed: {
    dynamicSize () {
      return [this.iconSize, this.iconSize * 1.15]
    },
    dynamicAnchor () {
      return [this.iconSize / 4, this.iconSize * 1.15]
    }
  },
  methods: {
    isEmpty (val) {
      return empty(val)
    },
    async getMapa () {
      const params = {
        url: '/meters079/map/list',
        method: 'GET',
        params: {
          number: '',
          serie: '',
          status: !empty(this.status) ? this.status.value : '',
          divisionUuid: this.$store.state.session.AppActiveUser().division.uuid
        }
      }
      await request(params).then(async response => {
        this.markers = []
        this.positions = []
        const positions1 = []
        this.responseDataCsv = []
        this.response = response.data.data
        this.status_mapa = true
        for (const item of this.response) {
          if (!empty(item.latlong)) {
            const latlongSplit = item.latlong.split(',')
            positions1.push([latlongSplit[0], latlongSplit[1]])
            this.addPosition(item)
            this.addMarker(item)

            this.responseDataCsv.push({
              name: item.cfeAgency?.name || 'S/D',
              number: item.number ?? 'S/D',
              street: item.street || 'S/D',
              numExt: item.numExt || 'S/D',
              suburb: item.suburb || 'S/D',
              postalCode: item.postalCode || 'S/D',
              location: item.location || 'S/D',
              municipality: item.municipality || 'S/D',
              state: item.state || 'S/D',
              latlong: item.latlong || 'S/D'
            })
          }
        }
        const app = this
        this.exportar()
        this.createCsv()
        if (positions1.length) {
          setTimeout(function () {
            const maps = app.$refs.mymap.mapObject
            if (!this.fullscreen) {
              maps.addControl(new L.Control.Fullscreen({
                position: 'topleft'
              }))
              this.fullscreen = true
            }
            maps.fitBounds(positions1)
            app.showButtons = true
          }, 1000)
        }
      })
    },
    exportar () {
      const res = GeoJSON.parse(this.marker2, { Point: ['lat', 'lng'] })
      const kml = tokml(res)
      const file = new Blob([kml], { type: 'text/kml' })
      this.urlDownload = window.URL.createObjectURL(file)
    },
    createCsv () {
      const csv = []
      const headers = []
      headers.push('Agencia')
      headers.push('Número de medidor')
      headers.push('Calle')
      headers.push('Num exterior')
      headers.push('Colonia')
      headers.push('Codigo postal')
      headers.push('Localidad')
      headers.push('Municipio')
      headers.push('Estado')
      headers.push('Longitud/Latitud')
      csv.push(headers.join(','))
      this.responseDataCsv.map(item => {
        const row = []
        row.push(item.name)
        row.push(item.meterNumber)
        row.push(item.street)
        row.push(item.numExt)
        row.push(item.suburb)
        row.push(item.postalCode)
        row.push(item.location)
        row.push(item.municipality)
        row.push(item.state)
        row.push(item.latlong)
        csv.push(row.join(','))
        return item
      })
      const file = csv.join('\n')
      const csvFile = new Blob([file], { type: 'text/csv' })
      this.urlDownloadCsv = window.URL.createObjectURL(csvFile)
    },
    deviceInformation (uuid) {
      this.$router.push(`/meters/GWH0079/${uuid}/detail`)
    },
    addPosition (element) {
      this.positions.push([element.latlong.split(',')[0], element.latlong.split(',')[1]])
    },
    addMarker (element) {
      this.markers.push({
        position: { lat: element.latlong.split(',')[0], lng: element.latlong.split(',')[1] },
        serie: element.serie,
        id: element.uuid,
        date: !empty(element.trasmitedAt) ? element.trasmitedAt : '',
        status: element.status,
        uuid: element.uuid,
        transmitting: element.transmitting,
        draggable: false,
        visible: true
      })
      this.marker2.push(
        {
          serie: element.serie,
          name: element.meterNumber ? element.meterNumber : '',
          status: element.status === '1' ? 'con servicio' : 'sin servicio',
          date: !empty(element.trasmitedAt) ? element.trasmitedAt : '',
          lat: element.latlong.split(',')[0],
          lng: element.latlong.split(',')[1]
        }
      )
    },
    fitBounds () {
      const maps = this.$refs.mymap.mapObject
      maps.fitBounds(this.positions)
    },
    formatDate (date) {
      return formatDateLanguages(date, 'hour')
    }
  },
  created () {
    this.getMapa()
  }
}
</script>
<style lang="scss">
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
